import React, {Component} from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout";
import * as PropTypes from "prop-types";
import Img from "gatsby-image";
import {Col, Row} from "antd";
import SEO from "../components/seo";

export default class PressPage extends Component {
    render() {
        let {data,} = this.props; // this prop will be injected by the GraphQL query below.

        const press = data.press.edges; //.filter(({node}) => node.parent.sourceInstanceName === "press");

        return (
            <Layout>
                <SEO title="Публикации"/>

                {press.map((item, idx) => {

                    let remark = item.node.childMarkdownRemark;
                    let {frontmatter} = remark;

                    return <Row key={idx} gutter={[32, 32]}>
                        <Col flex="175px"> {frontmatter.featuredImage != null ?
                            <a href={frontmatter.links[0].link} target="_blank" rel="noopener noreferrer">
                                <Img fluid={frontmatter.featuredImage.childImageSharp.fluid}
                                    // objectFit="contain"
                                    // style={{height: "280px"}}
                                    className="press-img"
                                />
                            </a>
                            : ""}
                        </Col>
                        <Col flex="50%">
                            <div className="press-container">
                                <div>{frontmatter.title}</div>

                                {frontmatter.links.map((l, idx) => {
                                    return <div style={{marginBottom: "5px"}} key={idx}>

                                        <a href={l.link} target="_blank" rel="noopener noreferrer"
                                           className="press-link">{l.title}</a>
                                        <div style={{marginBottom: "15px"}}>{l.publishedAt}</div>

                                    </div>
                                })}


                                {/*<div*/}
                                {/*    className="press-content"*/}
                                {/*    dangerouslySetInnerHTML={{__html: item.node.html}}*/}
                                {/*/>*/}
                            </div>
                        </Col>
                    </Row>
                })}
            </Layout>
        )
    }
}

PressPage.propTypes = {data: PropTypes.any};

export const pageQuery = graphql`
    query {
        press: allFile(filter: {extension: {regex: "/(md)/"}, sourceInstanceName: {eq: "press"}}, sort: {order: DESC, fields: [childMarkdownRemark___frontmatter___date]}) {
            edges {
                node {
                    name
                    sourceInstanceName
                    childMarkdownRemark {
                        html
                        frontmatter {
                            title
                            date
                            links {
                                title
                                link
                                publishedAt(formatString: "D MMMM YYYY", locale: "RU")
                            }
                            place
                            featuredImage {
                                childImageSharp {
                                    fluid(maxWidth: 1440, quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;